import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import {
  addPromotionalWorkshopCompletion,
  getPromotionalWorkshopCompletions
} from "api/db";

const PersonalWebsiteWebinar = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [completions, setCompletions] = useState([]);

  useEffect(() => {
    getPromotionalWorkshopCompletions(snapshot => {
      let data = snapshot.val();
      const object = { ...data };
      setCompletions(existing => existing.concat(object));
    });
  }, []);

  return (
    <>
      <main className="relative mx-auto max-w-screen-xl">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 z-10">
          <div className="text-center">
            <div
              className="py-1 bg-blue-800 hover:bg-blue-700 items-center text-blue-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                Workshop
              </span>
            </div>
          </div>
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-bold text-gray-900 ">
              Personal Website Workshop
            </h2>
            <p className="text-gray-600 max-w-lg mx-auto">
              If you liked this workshop, check out our two-week{" "}
              <Link to="/courses/web-development">
                <a className="text-blue-600 underline">web development course</a>
              </Link>{" "}
              starting on October 18th. You'll learn the fundamentals of HTML,
              CSS, JS by building three projects in a supoortive and accountable
              community.
            </p>
          </div>

          <section className="max-w-2xl mx-auto mt-4">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Name
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-100"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Website URL
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-100 focus:border-gray-500"
                  type="text"
                  placeholder="https://"
                  value={url}
                  onChange={e => {
                    setUrl(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex justify-center mb-4">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={() => {
                  if (name.length > 2 && url.length > 5) {
                    addPromotionalWorkshopCompletion(name, url);
                    setName("");
                    setUrl("");
                  } else {
                    alert("Please fill out both fields.");
                  }
                }}
              >
                Submit
              </button>
            </div>
          </section>

          <div className="p-4 max-w-xl mx-auto">
            <div className="text-right">
              <b>{completions.length}</b> submissions
            </div>
            <div className="flex justify-center">
              <div className="">
                {completions.map(completion => (
                  <div
                    className="w-full rounded overflow-hidden shadow px-16 py-8 m-4 text-center"
                    key={completion.url}
                  >
                    <p className="text-gray-900 text-xl">
                      <b>{completion.name}</b> built{" "}
                      <a
                        target="_blank"
                        href={completion.url}
                        className="text-blue-600 font-bold"
                      >
                        {completion.url}
                      </a>{" "}
                      &#8250;
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </main>
      <div className="bg-gray-100 p-4">
        <section className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="p-4 mx-auto max-w-3xl mt-8 mb-4 text-center rounded">
            <Link to="/courses/web-development">
              <h1 className="text-3xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                If you liked this workshop, check out the...
              </h1>
              <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-800 mt-2">
                Web Development Course
              </h1>
              <p className="mt-4 text-xl text-gray-700">
                Learn beginner web development concepts in just two weeks. Build
                three practical projects of your own and stay accountable with a
                supportive community.
              </p>
              <div className="mt-4 sm:mt-4 sm:flex justify-center">
                <div className="rounded-md shadow">
                  <Link to="/courses/web-development">
                    <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                      Learn more &#8250;
                    </a>
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default PersonalWebsiteWebinar;
